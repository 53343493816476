import axios from 'axios';

const ranchatApiCall = (code, packagename, success, error) => {
  axios.delete('https://api.strangerchat.io/api/v1/ranchatusers/code/' + code.toUpperCase() + '?package=' + packagename)
  // axios.delete('http://localhost:8090/api/v1/ranchatusers/code/' + code + '?package=' + packagename)
      .then(success)
      .catch(error)
}

const snapcalApiCall = (code, packagename, success, error) => {
  axios.delete('https://api.snap-cal.com/v1/appusers/' + code.toUpperCase() + '/{code}/external')
    .then(success)
    .catch(error)
}

const initDataAccount1 = {
  "icon": "",
  "name": "",
  "promotion": "",
  "by": "gaga.chat",
  "package": "",
  "api_call": ranchatApiCall,
  "email": "developer@strangerchat.io",
  "privacy": "",
}

export const appInfo = {
  "randomchat4": {
    "by": "gagasoft",
    "icon": "https://cdn.forestsoft.io/randomchat/icon.png",
    "name": "Random Chat - Stranger Chat",
    "package": "com.gagasoft.ranchat",
    "promotion": "https://lh3.googleusercontent.com/7NRnUDrmsLys2eSl-NL2JUuwirg1T7DCB96HrfENZ23tNa_EVjjmdlBWC1IK79H7KakE",
    "email": "topclass.meeting@gmail.com",
    "privacy": "https://www.gagasofts.com/policy/privacy-en.html"
  },
  "secretchat": {
    "by": "ParkMaro",
    "icon": "https://cdn.forestsoft.io/secretchat/icon.png",
    "name": "Secret Chat (Random Chat)",
    "promotion": "https://cdn.forestsoft.io/secretchat/promotion.png",
    "package": "com.suyong.secretchat",
    "email": "kalnalvid@gmail.com",
    "privacy": "https://www.thischatting.com/policy/privacy-en.html"
  },
  "lovetalk": {
    "by": "Ryan.dev",
    "icon": "https://cdn.forestsoft.io/lovetalk/icon.png",
    "name": "Random Talk, Stranger Chat",
    "package": "com.ryan.lovetalk",
    "promotion": "https://cdn.forestsoft.io/lovetalk/promotion.png",
    "email": "oneman.ryan@gmail.com",
    "privacy": "https://www.luvtalk.net/policy/privacy-en.html"
  },
  "sunshineseoul": {
    "by": "sunshine.seoul",
    "icon": "https://cdn.forestsoft.io/sunshineseoul/icon.png",
    "name": "Honey Talk",
    "package": "com.sunshineseoul.ranchat",
    "promotion": "https://cdn.forestsoft.io/sunshineseoul/promotion.png",
    "email": "sunshine.seoul.1016@gmail.com",
    "privacy": "https://www.thehoneytalk.com/policy/privacy-en.html"
  },
  "snapcal": {
    "by": "gagasoft",
    "icon": "https://www.snap-cal.com/asset/icon.png",
    "name": "Snap Cal: AI Calorie Tracker",
    "package": "com.gagasoft.snapcal",
    "promotion": "https://www.snap-cal.com/asset/promotion.png",
    "api_call": snapcalApiCall,
    "email": "topclass.meeting@gmail.com",
    "privacy": "https://www.snap-cal.com/policy/privacy.html"
  }
}