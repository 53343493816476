import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Card from '@mui/material/Card';
import {
  Alert,
  AlertTitle,
  Avatar,
  Backdrop,
  Button,
  CardContent,
  CardHeader,
  CardMedia,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Typography
} from "@mui/material";
import {useEffect, useState} from "react";
import LinkIcon from '@mui/icons-material/Link';
import {appInfo} from "../const/appinfo";

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    'min-height': '100vh'
  },
  box: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

function Withdraw() {
  const [app, setApp] = useState({
    "icon": null,
    "name": null,
    "promotion": null,
    "by": null,
    "package": null,
    "api_call": (code, success, error) => {},
    "email": null,
    "privacy": null,
  })

  const [code, setCode] = useState("")
  const [loading, setLoading] = useState(false)
  const [completed, setCompleted] = useState(false)
  const [errorOccurred, setErrorOccurred] = useState(false)

  const queryParams = new URLSearchParams(window.location.search);
  const appParam = queryParams.get('app') || "randomchat";

  const isValid = (code) => {
    return code.length === 10
  }

  useEffect(() => {
    if (appInfo[appParam]) {
      setApp(appInfo[appParam])
    }
  }, [])

  return (
    <Container sx={styles.container}>
      <Grid container justify="center" alignItems="center" item xs={8} lg={6}>
        <Box sx={styles.box}>
          <Card>
            <Typography
              variant="h5"
              display="block" gutterBottom
              textAlign="center"
              mt={2}
            >
              Account Deletion
            </Typography>
            <CardHeader
              avatar={
                <Avatar
                  variant="rounded"
                  src={app.icon}>
                </Avatar>
              }
              action={
                <a href={"https://play.google.com/store/apps/details?id=" + app.package} target="_blank">
                  <IconButton>
                    <LinkIcon/>
                  </IconButton>
                </a>
              }
              title={app.name}
              subheader={app.by}
            />
            <CardMedia
              component="img"
              image={app.promotion}
            />
            <CardContent>
              <Typography variant="subtitle2" gutterBottom>
                - <a href="https://play.google.com/store/account/subscriptions" target="_blank">
                  Subscriptions
                </a>&nbsp;
                are not auto-canceled and must be canceled manually.
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                - User Code is the code that is provided when you install the app. You can find it in the app.
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                - If you have any questions, please contact us at the email below
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                - All data will be deleted except for data in accordance with the <a
                href={app.privacy} target="_blank">Privacy Policy</a>
              </Typography>
              <TextField
                required
                fullWidth
                placeholder="User Code"
                id="filled-required"
                margin="normal"
                helperText={code.length !== 0 && !isValid(code) ? "It must have a length of 10." : ""}
                value={code}
                error={!isValid(code)}
                onChange={(event) => {
                  setCode(event.target.value)
                }}
              />
            </CardContent>
            <Typography
              variant="overline"
              display="block" gutterBottom
              textAlign="center"
              ml={2}
              mr={2}
            >
              <a href={"mailto:" + app.email + "?subject=Request Account Deletion&body=" + app.name}>
                {app.email}
              </a>
            </Typography>
            {
              errorOccurred &&
              <Alert severity="error">
                <AlertTitle>Failed</AlertTitle>
                You entered an invalid code.
              </Alert>
            }
            {
              completed &&
              <Alert severity="success">
                <AlertTitle>Success</AlertTitle>
                The Account deletion is completed
              </Alert>
            }
            <Button
              color="secondary"
              fullWidth
              size="large"
              disabled={!isValid(code)}
              style={{
                height: '70px'
              }}
              onClick={() => {
                setLoading(true)
                setCompleted(false)
                setErrorOccurred(false)
                app.api_call(code, app.package, () => {
                  setCompleted(true)
                  setLoading(false)
                  setCode("")
                }, (error) => {
                  setErrorOccurred(true)
                  setLoading(false)
                })
              }}
            >
              Account Deletion
            </Button>
          </Card>
        </Box>
      </Grid>
      <Backdrop
        sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
        open={loading}
      >
        <CircularProgress color="inherit"/>
      </Backdrop>
    </Container>
  );
}

export default Withdraw;
